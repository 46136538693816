.main-container {
  // width: 100vw;
  height: 100vh;
  background: #232938;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avtar {
  width: 200px;
  height: 200px;
  margin-left: 100px;
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  // white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 10s steps(40, end), blink-caret 1.5s step-end infinite;

  text-align: center;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.css-typing,
.css-typing-mobile {
  width: 100%;
  margin-left: 10%;
}

.css-typing-mobile {
  display: none;
}

.css-typing p,
.css-typing-mobile p {
  border-right: 0.15em solid orange;
  font-family: "Courier";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  color: white;
  line-height: 40px;
}
.css-typing p:nth-child(1) {
  width: 54%;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 69%;
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(3) {
  width: 18%;
  opacity: 0;
  -webkit-animation: type3 5s steps(20, end),
    blink 0.5s step-end infinite alternate;
  animation: type3 5s steps(20, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 600px) {
  .main-container {
    // width: 100vw;
    height: 100vh;
    background: #232938;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .avtar {
    width: 200px;
    height: 200px;
    margin-left: 0;
  }
  .css-typing {
    display: none;
  }

  .css-typing-mobile {
    display: block;
    width: 100%;
    margin-left: 14%;
    margin-top: 10%;
  }

  .avtar-wrapper {
    margin-top: 10%;
  }

  .css-typing-mobile p:nth-child(1) {
    width: 80%;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    // -webkit-animation-fill-mode: forwards;
    // animation-fill-mode: forwards;

    // -webkit-animation-delay: 8s;
    // animation-delay: 8s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing-mobile p:nth-child(2) {
    width: 80%;
    opacity: 0;
    -webkit-animation: type 2s steps(40, end), type2 2s steps(40, end),
      blink 0.5s step-end infinite alternate;
    animation: type 2s steps(40, end), type2 2s steps(40, end),
      blink 0.5s step-end infinite alternate;
    // -webkit-animation-fill-mode: forwards;
    // animation-delay: 2s;
    // animation-fill-mode: forwards;

    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing-mobile p:nth-child(3) {
    width: 80%;
    opacity: 0;
    -webkit-animation: type 2s steps(40, end), type2 2s steps(40, end),
      blink 0.5s step-end infinite alternate;
    animation: type 2s steps(40, end), type2 2s steps(40, end),
      blink 0.5s step-end infinite alternate;
    // -webkit-animation-fill-mode: forwards;
    // animation-delay: 4s;
    // animation-fill-mode: forwards;

    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing-mobile p:nth-child(4) {
    width: 80%;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end), type2 2s steps(40, end);
    animation: type2 2s steps(40, end), type2 2s steps(40, end);
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing-mobile p:nth-child(5) {
    width: 80%;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end), type2 2s steps(40, end);
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing-mobile p:nth-child(6) {
    width: 40%;
    opacity: 0;
    animation: type2 2s steps(40, end), type2 2s steps(40, end);
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .css-typing-mobile p:nth-child(7) {
    width: 80%;
    opacity: 0;
    -webkit-animation: type3 5s steps(20, end), type2 2s steps(40, end),
      blink 0.5s step-end infinite alternate;
    animation: type3 5s steps(20, end), blink 0.5s step-end infinite alternate;
    -webkit-animation-delay: 12s;
    animation-delay: 12s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}
